import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://84c18e268b3ee90a91bd54154ef66dc5@o4506644959985664.ingest.sentry.io/4506644963000320",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});